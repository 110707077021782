import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { API_TYPES } from 'data/types/api.types';
import { Helmet } from 'react-helmet';

import {
  Tooltip,
  IconButton,
  TextField,
  Divider,
  Button,
  Typography,
  Paper,
  Grid,
  ClickAwayListener,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';

import { apiKeySelector } from 'data/selectors/user';
import { playgroundResultSelector } from 'data/selectors/playground';
import { sendApiRequest, clearResult } from 'data/actions/playground';
import { playgroundLoaderSelector } from 'data/selectors/loading';
import {
  Container,
  CodeContainer,
  Code,
  PlaygroundContainer,
  ContainerDark,
  CodeDark,
  LinkIcon,
  ControlsContainer,
  ExampleContainer,
} from './styles';
import ApiRequestResult from 'components/apiRequestResult';
import * as CONSTANTS from 'constants/styles';

const API_HOST = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root, & .MuiFormControl-root': {
      marginTop: theme.spacing(CONSTANTS.SPACING_SMALL),
      marginBottom: theme.spacing(CONSTANTS.SPACING_SMALL),
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& label .MuiSvgIcon-root': {
      cursor: 'help',
    },
    '& .MuiSelect-select': {
      display: 'inline-flex',
    },
  },
  paper: {
    padding: theme.spacing(CONSTANTS.SPACING_LARGE),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    }
  },
  deviceSelect: {
    color: ({ isDeviceSelected }) => (!isDeviceSelected && CONSTANTS.COLORS.GRAY_G2),
  },
  formatSelect: {
    color: ({ isFormatSelected }) => (!isFormatSelected && CONSTANTS.COLORS.GRAY_G2),
  },
  autocompletePopper: {
    width: 'auto !important',
    minWidth: '300px',
    '& span': {
      color: CONSTANTS.COLORS.GRAY_G1,
      fontSize: 14,
    },
  },
  requestButton: {
    width: '100%',
  },
}));

const ApiPage = () => {
  const dispatch = useDispatch();

  const apiKey = useSelector(apiKeySelector);

  const [url, setUrl] = useState("https://httpbin.org/ip");
  const [render, setRender] = useState(false);
  const [visibleToolTip, setVisibleToolTip] = useState(false);
  const [visiblePlaygroundToolTip, setVisiblePlaygroundToolTip] = useState(false);
  const [requestUrl, setRequestUrl] = useState(`${API_HOST}?apiKey=${apiKey}&render=${render}&url=${url}`);
  const [apiType] = useState(API_TYPES.SCRAP);

  const classes = useStyles();

  useEffect(() => {
    let request = API_HOST;
    if (apiType === API_TYPES.SCRAP) {
      request = request + `/?apiKey=${apiKey}
          ${render ? `&render=${render}` : ''}
          ${url ? `&url=${url}` : ''}
        `;
    }
    setRequestUrl(request.replaceAll(/\s/g, ''));
  }, [apiType, apiKey, render, url]);

  const result = useSelector(playgroundResultSelector);
  const isLoading = useSelector(playgroundLoaderSelector);

  const handleCopy = (value, playground = false) => {
    if (playground) {
      setVisiblePlaygroundToolTip(true)
    } else {
      setVisibleToolTip(true)
    }
    navigator.clipboard.writeText(value);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>RocketScrape - API</title>
      </Helmet>

      <Grid container spacing={1} >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>API secret key</Typography>
              <CodeContainer onMouseLeave={() => setVisibleToolTip(false)}>
                <Code className={visibleToolTip ? 'animated' : undefined}>{apiKey}</Code>
                <ClickAwayListener onClickAway={() => setVisibleToolTip(false)}>
                  <IconButton
                    aria-label="copy"
                    onClick={() => handleCopy(apiKey)}
                  >
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      placement="top"
                      arrow
                      disableFocusListener
                      title="Copied"
                      disableHoverListener
                      onClose={() => setVisibleToolTip(false)}
                      disableTouchListener
                      open={visibleToolTip}
                    >

                      <CopyIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </ClickAwayListener>
              </CodeContainer>
              <Divider />
              <p style={{ marginTop: 20 }}>Your API keys are like your passwords: make sure to always keep them hidden! Share them only with services you trust.</p>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>API playground</Typography>
              <p>
                Using Scrap API is easy. Just send the URL you would like to scrape to the API along with your
                API key and the API will return the HTML response from the url you want to scrape.
              </p>
              <ExampleContainer onMouseLeave={() => setVisiblePlaygroundToolTip(false)}>
                <ContainerDark>
                  <CodeDark className={visiblePlaygroundToolTip ? 'animated' : undefined}>
                    GET {requestUrl}
                  </CodeDark>
                </ContainerDark>
                <ControlsContainer>
                  <ClickAwayListener onClickAway={() => setVisiblePlaygroundToolTip(false)}>
                    <Tooltip
                      title="Copied"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      arrow
                      disableFocusListener
                      disableHoverListener
                      onClose={() => setVisiblePlaygroundToolTip(false)}
                      disableTouchListener
                      open={visiblePlaygroundToolTip}
                    >
                      <LinkIcon onClick={() => handleCopy(requestUrl, true)} >
                        <CopyIcon fontSize="small" /> Copy
                      </LinkIcon>
                    </Tooltip>
                  </ClickAwayListener>
                  <LinkIcon onClick={() => window.open(requestUrl, '_blank')} ><OpenInNewOutlinedIcon fontSize="small" />Open in Tab</LinkIcon>
                </ControlsContainer>
              </ExampleContainer>

              <Divider />

              <PlaygroundContainer>
                <form noValidate autoComplete="off">
                  <Grid spacing={1} container
                    direction="row"
                    alignItems="center" >
                    <Grid container item xs={10}>
                      <TextField label="URL" variant="outlined" size="small" value={url} onChange={(e) => setUrl(e.target.value)} />
                    </Grid>
                    <Grid container item xs={2} direction="row">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.requestButton}
                        disabled={!url.length || !apiKey.length || isLoading}
                        onClick={() => {
                          dispatch(clearResult());
                          dispatch(sendApiRequest(requestUrl));
                        }}
                      >
                        Send request
                      </Button>
                    </Grid>
                    <Grid container item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={render}
                            onChange={(event) => setRender(event.target.checked)}
                          />}
                        style={{ margin: 0 }}
                        label="Render"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </form>
                <Grid container direction="row">
                  <ApiRequestResult result={result} loading={isLoading} />
                </Grid>
              </PlaygroundContainer>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ApiPage;

