import get from 'lodash/get';
import { ACCOUNT_EVENTS } from 'data/types/event.types';

const initialState = {
  usageAnalytics: null,
  usageLogs: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USAGE_ANALYTICS': {
      return {
        ...state,
        usageAnalytics: action.payload
      }
    }

    case 'GET_USAGE_LOGS': {
      return {
        ...state,
        usageLogs: action.payload
      }
    }
    case ACCOUNT_EVENTS.LOGS_EVENT: {
      const usage = get(action, 'payload.usage');
      const newLogs = [...state.usageLogs];
      newLogs.unshift(usage);
      return {
        ...state,
        usageLogs: newLogs,
      }
    }
    default:
      return state;
  }
};
