import React from 'react';
import MuiPhoneInput from 'material-ui-phone-number';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const PhoneInput = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiPhoneInput
        {...props}
        defaultCountry='us'
        disableAreaCodes
      />
    </div>
  )
};

export default PhoneInput;
