import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from 'constants/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';

import { CloseOutlined } from '@material-ui/icons';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';

const useStyles = makeStyles((theme) => {
  const getColor = (type) => {
    switch (type) {
      case 'success':
        return theme.palette.success.main;
      case 'error':
        return theme.palette.error.main;
      default:
        break;
    }
    return;
  };

  const style = {
    dialog: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
      },
      '& .MuiDialogActions-root': {
        margin: 0,
        padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        justifyContent: 'flex-start',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: `${CONSTANTS.DESKTOP_DIALOG_WIDTH}px`,
      }
    },
    header: {
      margin: 0,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: (props) => (getColor(props.type)),

      '& .MuiTypography-root': {
        color: CONSTANTS.COLORS.WHITE,
      }
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      color: CONSTANTS.COLORS.WHITE,
    },
    actionButton: {
      borderColor: (props) => (getColor(props.type)),
      color: (props) => (getColor(props.type)),
    },
  };

  return style;
});

const DialogHeader = ({ children, onClose, type, ...props }) => {
  const classes = useStyles({ type });
  return (
    <DialogTitle disableTypography className={classes.header} {...props}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const options = useSelector(modalOptionsSelector);
  const classes = useStyles({ type: options.type });

  const handleClose = () => {
    dispatch(toggleModal('dialog', false))
  };

  return (
    <Dialog className={classes.dialog} open={visible} fullWidth={true}>
      <DialogHeader onClose={handleClose} type={options.type}>
        {options.title}
      </DialogHeader>
      <DialogContent>
        <Typography gutterBottom variant="body1">
          {options.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={options?.onApprove}
          size="large"
          className={classes.actionButton}
        >
          {options.yesButtonText ?? 'Yes'}
        </Button>
        <Button
          variant="text"
          onClick={() => dispatch(toggleModal('dialog', false))}
          size="large"
          color="default"
        >
          {options.noButtonText ?? 'No'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogModal;
