import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import { toggleModal } from 'data/actions/modals';
import googleLogo from 'assets/icons/google.svg';
import PasswordInput from 'components/customInputs/password';
import EmailInput from 'components/customInputs/email';
import { firebaseApp, GoogleProvider } from 'auth';
import Button from 'components/button';
import { useHistory } from "react-router-dom";

import {
  LinkButton,
  ErrorMessage,
} from 'components/common/styles';
import AuthContainer from '../authContainer'

import {
  SocialButtonContainer,
  SocialButtonIcon,
  SocialButtonContent,
  SocialButtonsContainer,
  FormDivider,
  Text,
  Footer
} from './styles';

export const GoogleButton = ({ onClick, label }) => (
  <SocialButtonContainer onClick={onClick}>
    <SocialButtonIcon color="rgb(72, 133, 237)">
      {' '}
      <img alt="google" src={googleLogo} style={{ width: 25 }} />
      {' '}
    </SocialButtonIcon>
    <SocialButtonContent color="rgb(72, 133, 237)">
      {label}
    </SocialButtonContent>
  </SocialButtonContainer>
);

const Login = ({ authError }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const signInWithEmailAndPasswordHandler = async () => {
    setError(false);
    setLoading(true);
    try {
      await firebaseApp.signInWithEmailAndPassword(email, password);
      dispatch(toggleModal(false));
    } catch (err) {
      setError(get(err, 'message', "Error signing in with password and email"));
      setLoading(false);
    }
  };

  const disabled = !email || !password || loading;

  const signInWithGoogleHandler = async () => {
    setError(false);
    try {
      await firebaseApp.signInWithPopup(GoogleProvider);
      dispatch(toggleModal(false));
    } catch (err) {
      setError(get(err, 'message', "Google sign-in. Please, try with email and password"));
      setLoading(false);
    }
  };

  const changeRoute = (route) => {
    history.push(route);
  }

  return (
    <AuthContainer modalTitle='Welcome back 👋' pageTitle='Sign in'>
      <SocialButtonsContainer>
        <GoogleButton onClick={signInWithGoogleHandler} label="Continue with Google" />
      </SocialButtonsContainer>

      <FormDivider>
        or
      </FormDivider>

      <div style={{ marginBottom: 30 }}>
        <div style={{ margin: '10px 0' }}><EmailInput onChange={setEmail} value={email} placeholder="E-mail address" /></div>
        <div style={{ margin: '10px 0' }}><PasswordInput onChange={setPassword} value={password} placeholder="Password" /></div>
      </div>
      <LinkButton onClick={() => changeRoute('/reset-password')}>
        Forgot your password?
      </LinkButton>
      <div>
        {(error || authError) && <ErrorMessage>{error || authError}</ErrorMessage>}
        <Button onClick={signInWithEmailAndPasswordHandler} loading={loading} disabled={disabled} style={{ marginTop: 10 }}>
          Login
        </Button>

        <Footer>
          <Text>Don't have an account?</Text>
          <LinkButton onClick={() => changeRoute('/sign-up')}>
            Create account
          </LinkButton>
        </Footer>
      </div>
    </AuthContainer>
  );
};

export default Login;
