import React from 'react';
import { useSelector } from 'react-redux';

import { modalsSelector } from 'data/selectors/modals';
import AddCardModal from 'components/modals/addCardModal';
import DeleteCardModal from 'components/modals/deleteCardModal';
import NotificationModal from 'components/modals/notificationModal';
import DialogModal from 'components/modals/dialogModal';

const ModalsController = () => {
  const { type } = useSelector(modalsSelector);
  switch (type) {
    case 'add_card':
      return <AddCardModal />;
    case 'delete_card':
      return <DeleteCardModal />;
    case 'notification':
      return <NotificationModal />;
    case 'dialog':
      return <DialogModal />;
    default:
      return null;
  }
};

export default ModalsController;
