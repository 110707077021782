import React, { useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { applyCouponLoadingSelector } from 'data/selectors/loading';
import { applyCoupon, getAccount } from 'data/actions/user';
import PlansList from 'components/plansList';
import { Container } from './styles';
import * as CONSTANTS from 'constants/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root, & .MuiFormControl-root': {
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(CONSTANTS.SPACING_LARGE),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
  },
}));

const PlansPage = () => {
  const classes = useStyles();
  const applyCouponLoader = useSelector(applyCouponLoadingSelector); 
  const [code, setCode] = useState('');
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>Plans</Typography>
              <PlansList />
            </Container>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Typography variant="h6">Coupon code</Typography>
                <form noValidate autoComplete="off">
                  <Grid spacing={1} container
                    direction="row"
                    alignItems="center" >
                    <Grid container item xs={5}>
                      <TextField label="Enter coupon code" variant="outlined" size="small" value={code} onChange={(e) => setCode(e.target.value)} />
                    </Grid>
                    <Grid container item xs={2} spacing={0} direction="row">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={applyCouponLoader || !code || code?.length < 36}
                        onClick={() => {
                          dispatch(applyCoupon(code, () => {
                            dispatch(getAccount());
                            toast.success('Code successfully activated 🎉');
                          }, (err) => {
                            toast.error(`${get(err, 'response.data.title', 'Failed to activate code')} 😢`);
                          }));
                          setCode('');
                        }}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </form>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlansPage;
