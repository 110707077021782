import styled, { keyframes } from 'styled-components';
import { headShake } from 'react-animations';
import Select from 'react-select';

const headShakeAnimation = keyframes`${headShake}`;

export const Container = styled.div`
    text-align: left;

    > p {
      margin-top: 10px;
      margin-bottom: 0;
    }
`;

export const CodeContainer = styled.div`
  margin: 10px 0;

  .animated {
    animation: 1s ${headShakeAnimation};
  }
`;

export const Code = styled.code`
    color: #191919;
    background-color: #ecf0f0;
    border-radius: 3px;
    display: inline-block;
    padding: 0 5px;
    margin-right: 10px;
`;

export const PlaygroundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const ContainerDark = styled.div`
  background: #2e1f2e;
  padding: 1em;
  margin: .5em 0;
  overflow: hidden;
  overflow-x: scroll;
  border-radius: .3em;
  @media (max-width: 768px) {
    max-width: 220px;
    width: auto;
  }

  .animated {
    animation: 1s ${headShakeAnimation};
  }
`;

export const CodeDark = styled.code`
    color: #f8f8f2;
    background: none;
    text-shadow: 0 1px rgb(0 0 0 / 30%);
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    width: 100%;
`;

export const LinkIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FF5D22;
    font-weight: 500;
    margin-right: 10px;

    &:hover {
        text-decoration: underline;
    }
`;

export const ControlsContainer = styled.div`
    margin-top: 15px;
    display: flex;
`;

export const ResultContainer = styled.div`
    min-height: 350px;
    overflow-y: scroll;
    padding: 20px;
    background: #2e1f2e;
    border-radius: 5px;
    box-shadow: 12px 18px 48px 0 rgb(14 20 26 / 60%);
    width: 100%;
    position: relative;
`;
export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;

    span {
        margin-top: 10px;
    }
`;

export const ExampleContainer = styled.div`
  margin: 15px 0;
  width: 100%;
`;

export const ApiSelect = styled(Select)`
  max-width: 250px;
  margin: 12px 0;
  
  .sc__control {
    cursor: pointer;
  }

  .sc__option  {
    cursor: pointer;
  }
`;

export const Tag = styled.span`
    display: inline-block;
    height: auto;
    margin: 0 6px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    color: rgba(0,0,0,.65);
`;


export const Blur = styled.div`
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0000002b;
    z-index: 10;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


export const PlaceholderIcon = styled.img`
    width: 300px;
    margin-right: 45px;
    opacity: 0.8;
`;

export const PlaceholderLabel = styled.span`
    color: white;
    font-size: 18px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    font-weight: bold;
    opacity: 0.8;
`;

export const PlaceholderDescription = styled.span`
    color: white;
    opacity: 0.8;
    margin-bottom: 20px;
    margin-top: 10px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
`

export const CustomLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  div:nth-child(1) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis1 0.4s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    top: 0;
    animation: lds-ellipsis3 0.4s infinite;
  }
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
`;