import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
} from '@material-ui/core';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';

import {
  Title,
  Text,
} from './styles'

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: '50%',
    left:  '50%',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
    borderRadius: 5,
    border: '#ddd',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& h3': {
      color: (props) => {
        switch (props.type) {
          case 'success':
            return theme.palette.success.main;
          case 'error':
            return theme.palette.error.main;
          default:
            break;
        }
        return;
      },
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
}));

const NotificationModal = () => {
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { visible } = useSelector(modalsSelector);
  const options = useSelector(modalOptionsSelector);
  const classes = useStyles({ type: options.type, });

  const handleClose = () => {
    dispatch(toggleModal('notification', false))
  };

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Title>{options.title}</Title>
          <Text>{options.message}</Text>
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              onClick={() => dispatch(toggleModal('notification', false))}
              size="medium"
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NotificationModal;


