import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'

import { 
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { requestUsageSelector } from 'data/selectors/user'
import BorderLinearProgress from 'components/borderLinearProgress'
import * as CONSTANTS from 'constants/styles';

import {
  BarContainer,
  TitleContainer,
  BarHeader,
  BarTitle,
  BarLimit
} from './styles'


const UsageBars = () => {
  const {
    requestCount,
    requestLimit,
    concurrentLimit,
    concurrentRequests
  } = useSelector(requestUsageSelector)
  const [progressCredits, setProgressCredits] = useState(0);
  const [progressConcurrent, setProgressConcurrent] = useState(0);

  useEffect(() => {
    setProgressCredits(Math.ceil((requestCount / requestLimit) * 100));
  }, [requestCount, requestLimit])

  useEffect(() => {
    setProgressConcurrent(Math.ceil((concurrentRequests / concurrentLimit) * 100));
  }, [concurrentLimit, concurrentRequests])

  return (
    <Grid item xs={12}>
      <TitleContainer>
        <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>Usage</Typography>
      </TitleContainer>
      <BarContainer>
        <BarHeader>
          <BarTitle>Requests:</BarTitle>
          <BarLimit>{`${requestCount}/${requestLimit}`}</BarLimit>
        </BarHeader>
        <BorderLinearProgress value={progressCredits} />
      </BarContainer>

      <BarContainer>
        <BarHeader>
          <BarTitle>Concurrent:</BarTitle>
          <BarLimit>{`${concurrentRequests}/${concurrentLimit}`}</BarLimit>
        </BarHeader>
        <BorderLinearProgress value={progressConcurrent} />
      </BarContainer>
    </Grid>      
  )
}

export default UsageBars;

