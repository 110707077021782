const initialState = {
  usagePage: false,
  userAccount: true,
  usageLogs: false,
  usageAnalytics: false,
  playgroundResult: false,
  applyCoupon: false,
  freshping: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LOADING_COMPONENT': {
      const { components, loading } = action.data;

      components.forEach((item) => {
        state[item] = loading;
      });

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
