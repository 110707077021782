import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";
import {
  TablePagination,
  TableContainer,
  Paper,
} from '@material-ui/core';

import { usageLogsSelector } from 'data/selectors/usage'
import { requestCountSelector } from 'data/selectors/user'
import { getUsageLogs } from 'data/actions/usage';
import { usageLogsLoadingSelector } from 'data/selectors/loading'
import Loader from 'components/loading'
import TablePaginationActions from './paginationActions'
import columns from './columns'

import {
  PaginationContainer,
  TableWrapper
} from './styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    boxShadow: 'none',
  },
});

const TableLogs = () => {
  const dispatch = useDispatch()
  const usageLogs = useSelector(usageLogsSelector);
  const totalItems = useSelector(requestCountSelector);
  const isLoading = useSelector(usageLogsLoadingSelector);
  const [data, setData] = useState([])
  const classes = useStyles();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setData(usageLogs.map((item) => ({ id: item._id, ...item })))
  }, [usageLogs])

  useEffect(() => {
    if (page !== null) {
      dispatch(getUsageLogs(page + 1, 10))
    }
  }, [dispatch, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer className={classes.paper} component={Paper}>
      {
        isLoading
        ? <Loader height="200px" />
        : (
          <TableWrapper>
            <DataGrid
              rows={data}
              columns={columns}
              disableColumnMenu
              loading={false}
              onPageChange={handleChangePage}
              page={0}
              pageSize={10}
              hideFooterPagination
              autoHeight
              hideFooter
              rowHeight={40}
              headerHeight={40}
            />
            {totalItems > 10 && (
              <PaginationContainer>
                <TablePagination
                  count={totalItems}
                  rowsPerPage={10}
                  page={page ? page : 1}
                  onChangePage={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </PaginationContainer>
            )}
          </TableWrapper>
        )
      }
    </TableContainer>
  );
}

export default TableLogs;
