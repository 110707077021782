import React, { useEffect, useState } from 'react';
import { Container, Label, LogoContainer } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from 'assets/icons/logo.svg';


const labels = [
    'Loading your account...',
    'Calculating usage...',
    'Preparing Playground...',
]
const FullScreenLoader = () => {
    const [label, setLabel] = useState(labels[0]);
    
    useEffect(() => {
        let i = 0;

        const interval = setInterval(() => {
            i = (i === labels.length -1) ? 0 : i + 1;
            setLabel(labels[i]);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Container>
            <CircularProgress color="inherit" />
            <Label key={label}>{label}</Label>
            <LogoContainer>
                <img src={logo} style={{ width: 200 }} alt="" />
            </LogoContainer>
        </Container>
    )
} 

export default FullScreenLoader;
