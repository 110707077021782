import styled from 'styled-components';
import Button from '@material-ui/core/Button';


export const Text = styled.p`
  margin: 16px;
  text-align: center;
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
  margin: 10px auto;
`;