export const DESKTOP_SIDEBAR_WIDTH = 270;
export const LAPTOP_SIDEBAR_WIDTH = 240;
export const DESKTOP_LOGO_WIDTH = 240;
export const LAPTOP_LOGO_WIDTH = 210;
export const DESKTOP_USAGE_ITEM_HEIGHT = 180;
export const DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT = 500;
export const DESKTOP_SIDEBAR_MENU_BUTTON_THEME_MARGIN = 2;
export const SPACING_LARGE = 3;
export const SPACING_MEDIUM = 2;
export const SPACING_SMALL = 1;
export const DESKTOP_DIALOG_WIDTH = 530;

export const COLORS = {
  WHITE: '#fff',
  LIGHT_BLUE: 'rgba(255, 255, 255, 0.15)',
  BLUE_B1: '#1a90ff',
  GRAY_G1: '#bfbfbf',
  GRAY_G2: '#a2a2a2',
  GRAY_G3: 'rgba(51,51,51,.5)',
  GRAY_G4: '#f3f5f4',
};

export const SHADOWS = {
  PAPER_SHADOW: '0px 3px 15px rgb(0 0 0 / 8%)',
};
