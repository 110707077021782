import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as CONSTANTS from 'constants/styles';

export const Container = styled.div`
  background: linear-gradient(111.34deg,#2d357a,#0a1142);
  color: #ffffff;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  padding: 10px 0;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoContainer = styled(Link)`
  padding: 10px 0 30px 0px;
  display: flex;
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;

export const NavGroupTitle = styled.div`
  padding: 0 20px;
  color: inherit;
  opacity: 0.5;
  font-size: 0.75rem;
  box-sizing: border-box;
  list-style: none;
  font-weight: 400;
  line-height: 48px;
  text-transform: uppercase;
  z-index: 1;
  position: sticky;
  background-color: inherit;
`;

export const MenuItem = styled.a`
 color: #fff;

  svg {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
`

export const NavItem = styled(Link)`
  color: #fff;

  svg {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
`;

export const MobileMenuContainer = styled.div`
  display: none;
  background: linear-gradient(111.34deg,#2d357a,#0a1142);
  color: #ffffff;
  z-index: 10;
  width: 100%;
  position: fixed;
  padding: 0 16px;
  height: 56px;


  @media (max-width: 600px) {
    display: block;
  }
`;

export const Logo = styled.img`
  width: ${CONSTANTS.DESKTOP_LOGO_WIDTH}px;

  @media(max-width: 1440px) {
    width: ${CONSTANTS.LAPTOP_LOGO_WIDTH}px;
  }
`;