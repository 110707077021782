import { combineReducers } from 'redux';

import modals from './modals';
import user from './user';
import usage from './usage';
import loading from './loading';
import playground from './playground';
import cards from './cards';
import google from './google';
import plans from './plans';
import freshping from './freshping';

export default combineReducers({
  modals,
  user,
  loading,
  usage,
  playground,
  cards,
  google,
  plans,
  freshping,
});
