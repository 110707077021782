import React from 'react';
import * as moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';

const columns = [
  {
    field: "id",
    headerName: "Id",
    flex: 0.25,
    hide: true,
    sortable: false,
    renderCell: (data) => data._id
  },
  {
    field: "domain",
    headerName: "Domain",
    flex: 0.25,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.25,
    sortable: false,
  },
  {
    field: "render",
    headerName: "Render",
    flex: 0.25,
    renderCell: (data) => get(data, 'row.render', false) ? 'Yes' : 'No',
    sortable: false,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 0.25,
    renderCell: (data) => get(data, 'row.cost', 1),
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Time",
    flex: 0.25,
    sortable: false,
    renderCell: (data) => (
      <>
        <Tooltip title={moment(get(data, 'row.createdAt')).format('MMMM Do YYYY, h:mm:ss A')}>
          <span>
            {moment(get(data, 'row.createdAt')).fromNow()}
          </span>
        </Tooltip>
      </>
    )
  }
];

export default columns