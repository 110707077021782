import styled from 'styled-components';

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Text = styled.p`
  font-weight: bold;
`;
export const TextCount = styled.p`
  font-weight: bold;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 10px 0;
  opacity: .5;
`;

export const TimezoneLabel = styled.a`
  span {
    margin-right: 10px;
    color: #000000;
  }
  margin-right: 10px;
  font-size: 12px;
`;
