import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { firebaseApp } from 'auth';
import { useHistory } from "react-router-dom";
import { resendEmailVerification } from 'auth'

import { userEmailSelector } from 'data/selectors/user'
import AuthContainer from '../authContainer'
import { Text, ButtonComponent } from './styles'


const ConfirmEmail = () => {
  const [time, setTime] = useState(0)
  const email = useSelector(userEmailSelector)
  const history = useHistory();

  useEffect(() => {
    let intervalId
    if (time > 0) intervalId = setInterval(() => setTime(time - 1), 1000)
    else clearInterval(intervalId)
    return () => clearInterval(intervalId);
  }, [time]);

  const handleBackToSignIn = () => {
    firebaseApp.signOut()
    history.push('/login')
  }

  const handleResendEmailVerification = () => {
    setTime(30)
    resendEmailVerification()
  }

  return (
    <AuthContainer modalTitle='Confirm your Email Address' pageTitle='Email Confirmation'>
      <Text>
        We need to confirm your email address.
        {' '}
        {email}
      </Text>
      <Text>
        Please click on the link in the verification email we have sent
        to confirm your address
      </Text>
      <Text>
        Can't see the email or see this page again?
      </Text>
      <ButtonComponent
        onClick={handleResendEmailVerification}
        variant="outlined"
        disabled={time > 0}
        style={{ marginBottom: '40px' }}
      >
        Resend email
        {time > 0 && ` (${time})`}

      </ButtonComponent>
      <ButtonComponent
        onClick={handleBackToSignIn}
        variant="outlined"
      >
        Back to sign in
      </ButtonComponent>
    </AuthContainer>
  );
};

export default ConfirmEmail;
