export const setAuth = (auth) => (dispatch) => {
  dispatch({
    type: 'SET_AUTH',
    data: { auth },
  });
  if (auth) {
    localStorage.setItem('auth', JSON.stringify(auth));
  } else {
    localStorage.removeItem('auth');
  }
};

export const getAccount = (onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_ACCOUNT',
    payload: {
      endpoint: '/api/user/account',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['userAccount'],
    onSuccess,
    onFailed,
  });
};


export const updateAccount = (data, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACCOUNT',
    payload: {
      endpoint: '/api/user/account',
      method: 'PATCH',
      body: data
    },
    onSuccess,
    onFailed,
  });
};

export const accountEventsSubscription = () => (dispatch) => {
  dispatch({
    type: 'ACCOUNT_EVENTS_SUBSCRIPTION',
    payload: {
      event: 'api/user/events',
    },
  });
};

export const applyCoupon = (code, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'APPLY_COUPON',
    payload: {
      endpoint: `/api/user/coupon/apply?code=${code}`,
      method: 'POST',
      delayLoad: 0,
    },
    components: ['applyCoupon'],
    onSuccess,
    onFailed,
  })
};

export const deleteAccount = (onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'DELETE_ACCOUNT',
    payload: {
      endpoint: '/api/user/account',
      method: 'DELETE',
    },
    onSuccess,
    onFailed,
  });
};
