import React from "react";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";

const height = 35;

const MenuList = ({ options = [], children, maxHeight, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const FastSelect = (props) => (
  <Select
    filterOption={createFilter({ ignoreAccents: false })}
    components={{ MenuList }}
    className="fast-select"
    classNamePrefix="rs"
    {...props}
  />
);

export default FastSelect;

