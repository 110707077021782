import React from 'react';
import Button from 'components/button';
import { useHistory } from "react-router-dom";

import {
  Description,
} from '../resetPassword/styles';
import AuthContainer from '../authContainer'


const ResetLetter = () => {
  const history = useHistory();

  const changeRoute = (route) => {
    history.push(route);
  }

  return (
    <AuthContainer modalTitle='Check your email 👌' pageTitle='Reset Letter'>
        <Description>
          Recovery letter has been sent
        </Description>

        <div>
          <Button onClick={() => changeRoute('/login')}  style={{ backgroundColor: "#4885ed", color: "white", margin: '15px 0 5px 0'}}>Login</Button>
        </div>
    </AuthContainer>
  );
};

export default ResetLetter;
