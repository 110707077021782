import get from 'lodash/get';
import { createSelector } from 'reselect';

export const baseAuthSelector = (state) => get(state, 'user.auth', false);
export const baseUserSelector = (state) => get(state, 'user', {});

export const isAuthSelector = createSelector(
  baseAuthSelector,
  (auth) => !!auth,
);

export const accountSelector = createSelector(
  baseUserSelector,
  (user) => user?.account || null,
);

export const timezoneSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.timezone', 'UTC'),
);


export const userEmailSelector = createSelector(
  baseAuthSelector,
  (auth) => get(auth, 'email', ''),
);

export const userDataSelector = createSelector(
  baseUserSelector,
  (user) => user.auth,
);

export const apiKeySelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.apiKey', ""),
);

export const requestLimitSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.requestLimit', 0),
);

export const concurrentLimitSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.concurrentLimit', 0),
);

export const requestCountSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.requestCount', 0),
);

export const requestUsageSelector = createSelector(
  baseUserSelector,
  (user) => ({
    requestLimit: get(user, 'account.requestLimit', 1000),
    requestCount: get(user, 'account.requestCount', 0),
    concurrentRequests: get(user, 'account.concurrentRequests', 0),
    concurrentLimit: get(user, 'account.concurrentLimit', 0),
  }),
);

export const emailVerifiedSelector = createSelector(
  baseAuthSelector,
  (auth) => get(auth, 'emailVerified', false),
);

export const subscriptionStartSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.subscriptionStart'),
);

export const planNameSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.planName'),
);

export const subscriptionStatusSelector = createSelector(
  baseUserSelector,
  (user) => get(user, 'account.subscriptionStatus'),
);
