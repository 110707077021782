import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseLoadingSelector = (state) => get(state, 'loading');

export const userAccountLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.userAccount,
);

export const usageAnalyticsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.usageAnalytics,
);

export const usageLogsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.usageLogs,
);

export const playgroundLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.playgroundResult,
);

export const applyCouponLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.applyCoupon,
);

export const freshpingLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.freshping,
)
