import styled from 'styled-components';
import {
  Button,
}from '@material-ui/core';

export const ButtonComponent = styled(Button)`
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const Container = styled.div`
  #field-wrapper {
    border: 1px solid #ccc;
    width: 100%;
    height: 56px;
    margin: 16px 0 8px 0;
  }
`;