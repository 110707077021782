const initialState = {
  status: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FRESHPING': {
      const status = action?.payload?.status;
      return {
        ...state,
        status,
      }
    }
    default:
      return state;
  }
}
