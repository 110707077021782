import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;

  h6 {
    margin-bottom: 10px;
  }

  .MuiOutlinedInput-input {
    padding: 12px;
  }
`;

export const SettingsItems = styled.div`
  .makeStyles-selectItem-3 {
    flex: 0 0 48%;
    width: 100%;
    margin: 12px 0;
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
  height: 40px;
  float: right;
`;
