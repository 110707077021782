import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: 100%;
  cursor: pointer;
  user-select: none;
  line-height: 18px;
  font-size: 15px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-stretch: normal;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 42px;
  flex: 1 1 auto;
  border-radius: 4px;
  outline: 0px;
  border-width: 0px;
  border-style: initial;
  border-image: initial;
  transition: background 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  padding: 0px 18px;

    color: ${(props) => (props.disabled ? 'rgba(0,0,0,.25)' : 'rgb(255, 255, 255)')};
    background: ${(props) => (props.disabled ? '#f5f5f5' : 'rgb(0, 85, 255)')};
    border-color: ${(props) => (props.disabled ? '#d9d9d9' : 'initial')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
    
    span {
      margin-right: 10px;
    }

    &:hover {
      background: rgb(0, 76, 229);
    }
`;
