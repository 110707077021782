import React from 'react';
import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom'
import { firebaseApp } from 'auth';
import * as CONSTANTS from 'constants/styles';

import {
  Timeline as TimelineIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  Description as DescriptionIcon, 
  HelpOutline as HelpOutlineIcon, 
  ShoppingBasketOutlined as ShoppingBasketIcon,
  Code as ApiIcon
} from '@material-ui/icons';

import LogoImg from 'assets/icons/logo_white.svg'

import {
  Container,
  LogoContainer,
  NavGroupTitle,
  NavItem,
  MobileMenuContainer,
  Logo,
  MenuItem
} from './styles';


const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: CONSTANTS.DESKTOP_SIDEBAR_WIDTH,
      flexShrink: 0,
      '@media (max-width:1440px)': {
        width: CONSTANTS.LAPTOP_SIDEBAR_WIDTH,
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(CONSTANTS.DESKTOP_SIDEBAR_MENU_BUTTON_THEME_MARGIN),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: CONSTANTS.DESKTOP_SIDEBAR_WIDTH,
    '@media (max-width:1440px)': {
      width: CONSTANTS.LAPTOP_SIDEBAR_WIDTH,
    },
  },
  active: {
    backgroundColor: `${CONSTANTS.COLORS.LIGHT_BLUE} !important`,
  }
}));


const Sidebar = ({ window: componentWindow }) => {
  const { pathname } = useLocation()
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenChat= () => {
    if (window?.Tawk_API) window.Tawk_API.maximize();
  }

  const drawer = (
    <Container>
      <List>
        <LogoContainer  to='/usage'>
          <Logo src={LogoImg} />
        </LogoContainer>
        
        <NavGroupTitle>
          Account
        </NavGroupTitle>

        <NavItem to='/usage'>
          <ListItem button className={pathname === '/usage' ? classes.active : ''}>
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary='Usage' />
          </ListItem>
        </NavItem>

        <NavItem to='/api'>
          <ListItem button className={pathname === '/api' ? classes.active : ''}>
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            <ListItemText primary='API Playground' />
          </ListItem>
        </NavItem>

        <NavItem to='/settings'>
          <ListItem button className={pathname === '/settings' ? classes.active : ''}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </NavItem>

        <NavItem to='/plans'>
          <ListItem button className={pathname === '/plans' ? classes.active : ''}>
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText primary='Plans' />
          </ListItem>
        </NavItem>
        <MenuItem href='https://docs.rocketscrape.com' target='_blank'>
          <ListItem button>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary='Documentation' />
          </ListItem>
        </MenuItem>
      </List>
      <List>
        <NavGroupTitle>
          Support
        </NavGroupTitle>

        <MenuItem onClick={handleOpenChat}>
          <ListItem button>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary='Contact Support' />
          </ListItem>
        </MenuItem>

        <NavItem to='/login' onClick={() => firebaseApp.signOut()}>
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Sign Out' />
          </ListItem>
        </NavItem>
      </List>
    </Container>
  );

  const container = componentWindow !== undefined ? () => componentWindow().document.body : undefined;
  return (
    <div>
      <MobileMenuContainer>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
      </MobileMenuContainer>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer} 
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default Sidebar;
