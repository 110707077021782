const initialState = {
  countries: [],
  languages: [],
  domains: [],
  locations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_COUNTRIES': {
      const countries = action.payload;
      return {
        ...state,
        countries,
      }
    }
    case 'GET_LANGUAGES': {
      const languages = action.payload;
      return {
        ...state,
        languages,
      }
    }
    case 'GET_DOMAINS': {
      const domains = action.payload;
      return {
        ...state,
        domains,
      }
    }
    case 'GET_LOCATIONS': {
      const locations = action.payload;
      return {
        ...state,
        locations,
      };
    }
    default:
      return state;
  }
}
