import get from 'lodash/get';
import { ACCOUNT_EVENTS } from 'data/types/event.types';

const storageAuth = localStorage.getItem('auth');

const initialState = {
  auth: JSON.parse(storageAuth) || false,
  account: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH': {
      return {
        ...state,
        auth: action.data.auth,
      };
    }

    case 'GET_ACCOUNT': {
      return {
        ...state,
        account: action.payload,
      }
    }

    case 'UPDATE_ACCOUNT': {
      return {
        ...state,
        account: action.payload,
      }
    }

    case ACCOUNT_EVENTS.CREDITS_EVENT: {
      try {
        const requestCount = parseInt(get(action, 'payload.requestCount'), 10);
        const concurrentRequests = parseInt(get(action, 'payload.concurrentRequests'), 10);
        
        const { account } = state;
        
        if (requestCount) account.requestCount = requestCount;
        if (concurrentRequests || concurrentRequests === 0) account.concurrentRequests = concurrentRequests;
        return { ...state, account};
      } catch {
        return state;
      }
    }

    default:
      return state;
  }
};
