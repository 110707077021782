
import React, { useState } from 'react';
import { RelativeContainer } from '../common/styles';
import { Container, Placeholder, IconContainer } from './styles';

const Input = ({
  type = 'text', onChange = () => {}, value = '', placeholder = '', iconBefore, iconAfter, name, style,
}) => {
  const [isFocus, setFocus] = useState(false);
  return (
    <Container style={style}>
      { iconBefore && (
        <IconContainer>{iconBefore}</IconContainer>
      )}
      <RelativeContainer>
        <input type={type} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} onChange={(e) => onChange(e.target.value)} value={value} name={name} />
        { placeholder && <Placeholder style={{ transform: isFocus || value.length > 0 ? 'scale(0.85) translateY(-7px)' : '' }}>{ placeholder }</Placeholder> }
      </RelativeContainer>
      { iconAfter && (
        <IconContainer>{iconAfter}</IconContainer>
      )}
    </Container>
  );
};

export default Input;
