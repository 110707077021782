import styled from 'styled-components';

export const MeterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
`;

export const MeterItem = styled.div`
    width: 24px;
    height: 6px;
    margin-right: 1px;
    transition: background .2s cubic-bezier(.455,.03,.515,.955);
`;
