import styled from 'styled-components';

export const Container = styled.div`
    min-height: 350px;
    height: 500px;
    margin-top: 20px;
    padding: 20px;
    background: #2e1f2e;
    border-radius: 5px;
    box-shadow: 4px 4px 10px 0 rgb(14 20 26 / 60%);
    width: 100%;
    position: relative;
    overflow: scroll;

    code {
      font-size: 0.875rem !important;
      font-family: monospace !important;
      color: rgb(249, 155, 21) !important;
      min-height: 350px;
      white-space: pre-line;
    }
`;

export const Blur = styled.div`
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0000002b;
    z-index: 10;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


export const PlaceholderIcon = styled.img`
    width: 300px;
    margin-right: 45px;
    opacity: 0.8;
`;

export const PlaceholderLabel = styled.span`
    color: white;
    font-size: 18px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    font-weight: bold;
    opacity: 0.8;
`;

export const LoadingLabel = styled.span`
    color: white;
    font-size: 14px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 10px;
`;

export const PlaceholderDescription = styled.span`
    color: white;
    opacity: 0.8;
    margin-bottom: 20px;
    margin-top: 10px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
`

export const CustomLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  div:nth-child(1) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis1 0.4s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    top: 0;
    animation: lds-ellipsis3 0.4s infinite;
  }
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
`;