import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import get from 'lodash/get';
import { ToastContainer } from 'react-toastify';
import { setAuth, getAccount } from 'data/actions/user';
import { isAuthSelector, emailVerifiedSelector } from 'data/selectors/user';
import { accountSelector } from 'data/selectors/user';
import { usageAnalyticsSelector, usageLogsSelector } from 'data/selectors/usage';
import { firebaseApp } from './auth';
import { getUsageAnalytics } from 'data/actions/usage';
import { getUsageLogs } from 'data/actions/usage';

import UsagePage from 'pages/usage';
import Login from 'pages/auth/login';
import SignUp from 'pages/auth/signUp';
import ResetPassword from 'pages/auth/resetPassword';
import ResetLetter from 'pages/auth/resetLetter';
import Api from 'pages/api';
import SettingsPage from 'pages/settings';
import EnterPassword from 'pages/auth/enterPassword';
import ConfirmEmail from 'pages/auth/confirmEmail';
import ModalsController from 'components/modalsController';
import CommonContainer from 'components/common';
import PlansPage from 'pages/plans';
import FullScreenLoader from 'components/fullScreenLoader';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const emailVerified = useSelector(emailVerifiedSelector)
  const account = useSelector(accountSelector);
  const logs = useSelector(usageLogsSelector);
  const analytics = useSelector(usageAnalyticsSelector); 
  const [authError, setAuthError] = useState();

  useEffect(() => {
    const unlisten = firebaseApp.onAuthStateChanged(
      (user) => {
        if (user) {
          dispatch(getAccount(() => {
            dispatch(setAuth(user));
            dispatch(getUsageAnalytics('month'));
            dispatch(getUsageLogs(1));
          }, (err) => {
            setAuthError(get(err, 'response.data.details', 'Something went wrong'));
            dispatch(setAuth(false));
            localStorage.removeItem('authUser');
          }));
        } else {
          dispatch(setAuth(false));
          localStorage.removeItem('authUser');
        }
        return true;
      },
    );
    return () => {
      unlisten();
    };
  }, [dispatch]);

  if (isAuth && (account === null | analytics === null | logs === null)) {
    return <FullScreenLoader />;
  }

  if (isAuth && !emailVerified) return (
    <Router>
      <Switch>
        <Route
          path="/email-confirmation"
          component={ConfirmEmail}
        />
        <Redirect to="/email-confirmation" />
      </Switch>
    </Router>
  );

  return (
    <Router>
      <>
        <Switch>
          {isAuth
            ? <CommonContainer>
                <Switch>
                  <Route exact path='/usage' component={UsagePage} />
                  <Route exact path='/api' component={Api} />
                  <Route exact path='/settings' component={SettingsPage} />
                  <Route exact path='/plans' component={PlansPage} />
                  <Route exact path='/enter-password' component={EnterPassword} />
                  <Redirect to="/usage" />
                </Switch>
                <ModalsController />
                <ToastContainer />
              </CommonContainer>
            :
              <Switch>
                <Route path='/login' render={(props) => <Login {...props} authError={authError}/>}/>
                <Route exact path='/sign-up' render={(props) => <SignUp {...props} authError={authError}/>}/>
                <Route exact path='/reset-password' component={ResetPassword} />
                <Route exact path='/enter-password' component={EnterPassword} />
                <Route exact path='/reset-letter' component={ResetLetter} />
                <Redirect to='/login' />
              </Switch>
          }

        </Switch>
      </>
    </Router>
  );
};

export default App;
