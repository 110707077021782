import React from 'react';
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TableLogs from 'components/tableLogs'
import ChartRequests from 'components/chartRequests'
import { userAccountLoadingSelector } from 'data/selectors/loading'
import Loader from 'components/loading'
import { usageLogsSelector } from 'data/selectors/usage';
import * as CONSTANTS from 'constants/styles';

import UsageBars from 'components/usageBars';
import Subscription from 'components/subscription';
import HealthStatus from 'components/healthStatus';

import {
  InfoContainer,
  PlaceholderLabel,
  PlaceholderDescription,
} from './styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(CONSTANTS.SPACING_LARGE),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
  },
  paperTable: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
  },
  placeholder: {
    padding: theme.spacing(CONSTANTS.SPACING_LARGE),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
}));

const UsagePage = () => {
  const classes = useStyles();
  const isLoading = useSelector(userAccountLoadingSelector);
  const history = useHistory();
  const logs = useSelector(usageLogsSelector);

  return isLoading
    ? <Loader height="100vh" />
    : (
      <div className={classes.root}>
        <Helmet>
          <title>RocketScrape - Usage</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <InfoContainer>
                <Grid container>
                  <UsageBars />
                </Grid>
              </InfoContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <HealthStatus />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Subscription />
            </Paper>
          </Grid>
          {logs?.length === 0 ?
            <Grid item xs={12}>
              <Paper className={classes.placeholder}>
                <PlaceholderLabel>Let's start scraping!</PlaceholderLabel>
                <PlaceholderDescription>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => history.push('/api')}
                  >
                    Make your first scrap request
                  </Button>
                </PlaceholderDescription>
              </Paper>
            </Grid>
            : <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                  <ChartRequests />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paperTable}>
                  <TableLogs />
                  </Paper>
                </Grid>
              </>
          }
        </Grid>
      </div>
    )
}

export default UsagePage;

