import get from 'lodash/get';
import { CLOSE_EVENT_SOURCE_CONNECTION, EVENTS_DATA } from 'data/types/event.types';

// eslint-disable-next-line
export default () => (next) => async (action) => {
  const event = get(action, 'payload.event');
  const onSuccess = get(action, 'onSuccess', null);
  const onFailed = get(action, 'onSuccess', null);

  if (!event) return next(action);
  
  const apiHost = process.env.REACT_APP_API_URL;

  
  let accessToken = false;
  
  try {
    const storage = localStorage.getItem('auth');
    const auth = JSON.parse(storage);
    accessToken = get(auth, 'stsTokenManager.accessToken', false);
  } catch (err) {
    console.log(err);
  }

  const eventSource = new EventSource(`${apiHost}/${event}?jwtToken=${accessToken}`);

  eventSource.onopen = (e) => {
    if (onSuccess) onSuccess(e);
  };

  eventSource.onmessage = ({ data }) => {
    try {
      const message = JSON.parse(data);
      if (message.type) {
        next({
          type: message.type,
          payload: message.data,
        });
        const eventsData = new CustomEvent(EVENTS_DATA, { detail: message });
        window.dispatchEvent(eventsData);
      }
    } catch (err) { console.log('[EVENT SOURCE] message error', err); } // eslint-disable-line no-console
  };

  eventSource.onerror = (err) => {
    if (onFailed) onFailed(err);
  };

  const listener = window.addEventListener(CLOSE_EVENT_SOURCE_CONNECTION, () => {
    window.removeEventListener(CLOSE_EVENT_SOURCE_CONNECTION, listener);
    eventSource.close();
  });
};
