import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 100%;
  max-width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    max-width: 100%;
    justify-content: center;
  }
`;

export const BenefitsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const BenefitItem = styled.div`
  width: 50%;
  display: flex;
  line-height: 18px;
  font-size: 14px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-stretch: normal;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;

  span {
    color: rgb(43, 217, 130);
    margin-right: 10px
  }
`;

export const TextContainer = styled.div`
  line-height: 18px;
  font-size: 14px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-stretch: normal;
  font-weight: 400;
  color: rgb(33, 33, 33);
  text-align: center;
  margin-top: 10px
`;
