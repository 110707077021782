import styled from "styled-components";
import * as CONSTANTS from 'constants/styles';

export const HealthStatusContainer = styled.div`
  height: ${CONSTANTS.DESKTOP_USAGE_ITEM_HEIGHT}px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  text-align: left;
`;

export const HealthContainer = styled.div`
  display: inline-flex;
  align-items: center;
  float: left;

  svg {
    margin-right: 8px;
  }
`;

export const HealthPageLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  color: inherit;
  text-decoration: none;
  float: left;
  display: inline-flex;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const HealthForwardContainer = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    font-size: 14px;
    margin-left: 5px;
  }
`;
