const initialState = {
  data: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PLANS': {
      const data = action.payload;
      
      return {
        ...state,
        data,
      };
    }

    default:
      return state;
  }
};

