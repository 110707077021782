export const sendApiRequest = (url) => (dispatch) => {
    dispatch({
      type: 'SEND_API_REQUEST',
      payload: {
        url,
        method: 'GET',
        delayLoad: 0
      },
      components: ['playgroundResult']
    });
};

export const clearResult = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_RESULT',
    data: {},
  });
};