import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;
`;

export const PlanItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &:nth-child(2) {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

export const PlanSubItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 240px;
  &:first-child {
    padding-right: 20px;
    border-right: 1px solid #ddd;
  }

  &:nth-child(2) {
    padding-left: 20px;
  }
  &:nth-child(3) {
    min-width: auto;
    width: 100%;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: ${({ color }) => (color)};
`;

export const SubTitle = styled.span`
  font-size: 14px;
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: inline-flex;
  align-self: flex-end;
`;
