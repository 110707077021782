import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Bar } from 'react-chartjs-2';
import { 
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';

import { usageAnalyticsSelector } from 'data/selectors/usage'
import { getUsageAnalytics } from 'data/actions/usage'
import { timezoneSelector } from 'data/selectors/user'
import { usageAnalyticsLoadingSelector } from 'data/selectors/loading'
import Loader from 'components/loading'

import {
  TitleContainer,
  TopBar,
  Divider,
  TimezoneLabel,
} from './styles';
import { options, generateData } from './configuration'

const ChartRequests = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const usageAnalytics = useSelector(usageAnalyticsSelector);
  const isLoading = useSelector(usageAnalyticsLoadingSelector);
  const timezone = useSelector(timezoneSelector)
  const [period, setPeriod] = useState('month');

  const handleChange = (e) => {
    setPeriod(e.target.value);
    dispatch(getUsageAnalytics(e.target.value));
  };
  
  return (
    <>
      <TopBar>
        <TitleContainer>
          <Typography variant="h6">Request</Typography>
          <div>
            <TimezoneLabel onClick={() => history.push('/settings')}>
              <span>Timezone:</span>
              {timezone}
            </TimezoneLabel>
            <Select
              value={period}
              onChange={handleChange}
            >
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="hour">Hour</MenuItem>
            </Select>
          </div>
        </TitleContainer>
      </TopBar>
      <Divider />
      {
        isLoading
          ?  <Loader height={'313px'} />
          :  <Bar height={100} data={generateData(usageAnalytics, period, timezone)} options={options} />
      }
    </>
  );
};

export default ChartRequests;