import React from 'react';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { ButtonContainer } from './styles';

const Button = ({
  children, onClick, disabled = false, style, loading = false,
}) => (
  <ButtonContainer onClick={onClick} disabled={disabled} style={style}>
    { loading ? <LoadingOutlined /> : children }
  </ButtonContainer>
);


export default Button;
