import React from 'react';
import * as moment from 'moment';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { 
  Typography
} from '@material-ui/core';

import {
  requestLimitSelector,
  concurrentLimitSelector,
  subscriptionStartSelector,
  planNameSelector,
  subscriptionStatusSelector,
} from 'data/selectors/user'
import { formatCount } from 'helpers/formatCount'
import * as CONSTANTS from 'constants/styles';

import {
  TitleContainer,
  DateCreated,
  BillingPeriodContainer,
  StatisticContainer,
  StatisticItem,
  StatisticValue,
  StatisticTitle,
  SettingsButton,
  StatusIndicator,
} from './styles'

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: (props) => props.status === 'disabled' ? theme.palette.error.light : theme.palette.success.light, 
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.black,
    }
  },
}));

const Subscription = () => {
  const history = useHistory()
  const requestLimit = useSelector(requestLimitSelector)
  const concurrentLimit = useSelector(concurrentLimitSelector)
  const subscriptionStart = useSelector(subscriptionStartSelector);
  const planName = useSelector(planNameSelector);
  const subscriptionStatus = useSelector(subscriptionStatusSelector);
  const classes = useStyles({ status: subscriptionStatus })

  return (
    <BillingPeriodContainer>
      <TitleContainer>
        <div>
          <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>
            {capitalize(planName)} plan
          </Typography>
          <Tooltip 
            classes={{
              tooltip: classes.tooltip,
            }}
            placement="top"
            arrow
            title={`Your subscriptions is ${subscriptionStatus}`}
          >
            <StatusIndicator className={classes.indicator} />
          </Tooltip>
        </div>
        <SettingsButton onClick={() => history.push('settings')} />
      </TitleContainer>
      <DateCreated>{`Created ${moment(subscriptionStart).format('DD.MM.YYYY')}`}</DateCreated>    
      <StatisticContainer>
        <StatisticItem>
          <StatisticValue>{formatCount(requestLimit)} requests</StatisticValue>
          <StatisticTitle>Total</StatisticTitle>
        </StatisticItem>
        <StatisticItem>
          <StatisticValue>{concurrentLimit} requests</StatisticValue>
          <StatisticTitle>Concurrent</StatisticTitle>
        </StatisticItem>
      </StatisticContainer>
    </BillingPeriodContainer>
  )
}

export default Subscription;

