import React from 'react';
import {
  MailOutlined,
} from '@ant-design/icons';
import Input from '../input';

const EmailInput = ({ onChange, value, placeholder }) => (
  <Input
    type="email"
    name="email"
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    iconBefore={<MailOutlined />}
  />
);

export default EmailInput;
