import { nanoid } from 'nanoid'

const initialState = {
  data: [
    {
      isDefault:true,
      deleted:false,
      _id: nanoid() ,
      firstName:"test",
      lastName:"test",
      token:"test",
      number:"1111",
      type:"VISA",
      expireAt:"2023-12-01T00:00:00.000Z",
      country:"CA",
      createdAt:"2021-04-04T06:15:12.906Z",
      updatedAt:"2021-04-04T06:15:13.660Z",
    }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_CARD': {
      const newCard = {
        isDefault: false,
        deleted: false,
        _id: nanoid(),
        firstName: "test",
        lastName:"test",
        token: "test",
        number:"1111",
        type:"VISA",
        expireAt:"2023-12-01T00:00:00.000Z",
        country:"CA",
        createdAt:"2021-04-04T06:15:12.906Z",
        updatedAt:"2021-04-04T06:15:13.660Z",
      }

      const newCardsData = [ ...state.data, newCard ]

      return {
        ...state,
        data: newCardsData,
      };
    }

    case 'GET_CARDS': {
      return state
    }

    case 'DELETE_CARD': {
      const { cardId } = action.data
      const newCardsData = [...state.data].filter((item) => item._id !== cardId)
      return {
        ...state,
        data: newCardsData,
      };
    }

    default:
      return state;
  }
};
