export const toggleModal = (type, value, options) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_MODAL',
    data: {
      type,
      value,
      options,
    },
  });
};
