import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LoadingContainer } from './styles'


const Loading = ({ height, width}) => {
  return (
    <LoadingContainer height={height} width={width}>
      <CircularProgress />
    </LoadingContainer>
  );
};

export default Loading;