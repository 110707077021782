import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import get from 'lodash/get';
import { firebaseApp } from 'auth';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Typography,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import { accountSelector, userDataSelector } from 'data/selectors/user';
import { updateAccount, deleteAccount } from 'data/actions/user';
import { userAccountLoadingSelector } from 'data/selectors/loading';
import { toggleModal } from 'data/actions/modals';
import { toast } from 'react-toastify';
import Loader from 'components/loading';
import FastSelect from 'components/common/select';
import PhoneInput from 'components/phoneInput';
import * as CONSTANTS from 'constants/styles';

import {
  Container,
  SettingsItems,
  Avatar,
} from './styles';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(CONSTANTS.SPACING_MEDIUM),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
      '& .MuiButton-root': {
        width: '100%',
      },
      '& .MuiTypography-h6': {
        margin: 0,
      }
    },
    selectItem: {
      width: '100%',
      margin: '8px 0',

      [theme.breakpoints.down('sm')]: {
        flex: '0 0 100%',
      },

      '& .rs__control': {
        height: 41,
      },

      '& .rs__control--is-focused': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
        '&:hover': {
          borderColor: theme.palette.primary.main,
        }
      },
    },
    deleteButton: {
      color: theme.palette.error.light,
      borderColor: theme.palette.error.light,
    }
}));

const AccountSettings = () => {
    const dispatch = useDispatch();
    const account = useSelector(accountSelector);
    const accountData = useSelector(userDataSelector);
    const isLoading = useSelector(userAccountLoadingSelector);

    const gName = accountData?.displayName;
    const gFirstName = gName ? gName.split(' ')[0] : undefined;
    const gLastName = gName ? gName.split(' ')[2] : undefined;
    const classes = useStyles();
    const [firstName, setFirstName] = useState(get(account, 'firstName', gFirstName));
    const [lastName, setLastName] = useState(get(account, 'lastName', gLastName));
    const [phone, setPhone] = useState(account?.phone);
    const [timezone, setTimeZone] = useState(account?.timezone);
    const [timeZones, setTimeZones] = useState([]);

    const fields = ['firstName', 'lastName', 'email', 'phone', 'timezone'];

    const handleSave = () => {
      const newData = { firstName, lastName, phone, timezone };
      dispatch(updateAccount(newData, () => toast.success('Account was updated 🎉'), () => toast.error('Failed to update account 😢')));
    };

    const isDisabled = () => {
      let disabled = true;
      const newData = { firstName, lastName, phone, timezone };
      const googleData = { firstName: gFirstName, lastName: gLastName };

      fields.forEach((key) => {
        if (disabled && newData[key] && account[key] !== newData[key]){
          disabled = false;
          if (googleData[key] && newData[key] === googleData[key]) {
            disabled = true;
          }
        }
      });

      return disabled;
    };

    const onDelete = () => {
      dispatch(deleteAccount(
        () => firebaseApp.signOut(),
      ))
    };

    const showDeleteDialog = () => {
      dispatch(toggleModal('dialog', true, { 
        title: 'Delete account', 
        message: 'Are you sure you want to delete this account?',
        type: 'error',
        yesButtonText: 'Delete',
        noButtonText: 'Cancel',
        onApprove: onDelete,
      }));
    };

    useEffect(() => {
      const zones = moment.tz.names();
      setTimeZones(zones.map((item) => ({
        value: item, label: `(GMT ${moment.tz(item).format('Z')}) ${item}`
      })))
    }, []);
 
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Grid
                direction="row"
                container
                xs={6}
                spacing={1}
                item
              >
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>Account</Typography>
                </Grid>
                {accountData?.photoURL && <Grid item xs={6}>
                  <Avatar src={accountData?.photoURL} alt="avatar" />
                </Grid>}
              </Grid>
              {
                isLoading
                  ? <Loader height="124px" />
                  : 
                (
                  <SettingsItems>
                    <Grid
                      container
                      spacing={2}
                      direction="column"
                    >
                      <Grid 
                        item
                        direction="row"
                        spacing={2}
                        container
                      >
                        <Grid item xs={3}>
                          <TextField label="First name" variant="outlined" size="small" value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField label="Last name" variant="outlined" size="small" value={lastName || ''} onChange={(e) => setLastName(e.target.value)} />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <PhoneInput label="Phone" value={phone || ''} variant="outlined" size="small" onChange={(phone) => setPhone(phone)} />
                      </Grid>
                      <Grid item xs={6}>
                        <FastSelect
                          options={timeZones}
                          onChange={(item) => setTimeZone(item?.value)}
                          className={classes.selectItem}
                          value={timezone ? { value: timezone, label: timezone } : undefined}
                          placeholder="Select timezone"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          size="medium"
                          variant="outlined"
                          color="primary"
                          disabled={isDisabled()}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </SettingsItems>
                )}
            </Container>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper className={classes.paper}>
            <Container>
              <Grid
                  direction="row"
                  container
                  xs={12}
                  alignItems="center"
                  item
                >
                  <Grid container direction="column" xs={10} item>
                    <Typography variant="h6" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>Delete account</Typography>
                    <Typography variant="body2">If you delete your account, all data will be archived.</Typography>
                  </Grid>
                  <Grid container alignContent="flex-end" xs={2} item>
                    <Button className={classes.deleteButton} variant="outlined" onClick={showDeleteDialog}>Delete account</Button>
                  </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    );
}

export default AccountSettings;

