import React, { useState } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { firebaseApp, GoogleProvider } from 'auth';


import { toggleModal } from 'data/actions/modals';
import PasswordStrengthMeter from 'components/passwordStrengthMeter';
import PasswordInput from 'components/customInputs/password';
import EmailInput from 'components/customInputs/email';
import Button from 'components/button';
import { LinkButton, ErrorMessage } from 'components/common/styles';
import { useHistory } from "react-router-dom";
import {
  SocialButtonsContainer,
  FormDivider
} from '../login/styles'
import { GoogleButton } from 'pages/auth/login'
import AuthContainer from '../authContainer'

import {
  TextContainer,
} from './styles';

const SignUp = ({ authError }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const buttonDisabled = !email || !password || password.length < 6;

  const history = useHistory();
  const changeRoute = (route) => {
    history.push(route);
  }

  const createUserWithEmailAndPasswordHandler = async () => {
    setLoading(true);
    try {
      const { user } = await firebaseApp.createUserWithEmailAndPassword(email, password);
      if (user) {
        await user.sendEmailVerification();
        dispatch(toggleModal(true));
      }
    } catch (err) {
      setError(get(err, 'message', 'Error Signing up with email and password'));
    }
    setLoading(false);
  };

  const signInWithGoogleHandler = async () => {
    setError(false);
    try {
      await firebaseApp.signInWithPopup(GoogleProvider);
      dispatch(toggleModal(false));
    } catch (err) {
      setError("Google sign-in. Please, try with email and password");
      setLoading(false);
    }
  };

  return (
    <AuthContainer modalTitle='Ready to get started? 🙌' pageTitle='Sign up'>
      <SocialButtonsContainer>
        <GoogleButton onClick={signInWithGoogleHandler} label={"Continue with Google"} />
      </SocialButtonsContainer>

      <FormDivider>
        or
      </FormDivider>

      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <div style={{ margin: '10px 0' }}><EmailInput onChange={setEmail} value={email} placeholder="E-mail address" /></div>
        <div style={{ margin: '10px 0' }}><PasswordInput onChange={setPassword} value={password} placeholder="Password" /></div>
        <PasswordStrengthMeter password={password} />
      </div>

      <div>
        {(error || authError) && <ErrorMessage>{error || authError}</ErrorMessage>}
        <Button
          style={{ marginTop: 10 }}
          disabled={buttonDisabled}
          loading={loading}
          onClick={createUserWithEmailAndPasswordHandler}
        >
          Sign up for free
        </Button>
        <TextContainer>
          <span>
              You already have an account?
            <LinkButton onClick={() => changeRoute('/login')}>
              Login
            </LinkButton>
          </span>
        </TextContainer>
      </div>
    </AuthContainer>
  );
};

export default SignUp;
