import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiTableCell-root {
    border-bottom: none;

    .MuiTypography-body2 {
      display: none;
    }

    .MuiInputBase-root {
      display: none;
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .MuiDataGrid-root {
    border: none;

    .MuiDataGrid-colCell:focus, .MuiDataGrid-cell:focus {
      outline: none !important;
    }  
  }
`;
