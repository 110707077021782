import React, { useState } from 'react';
import PasswordInput from 'components/customInputs/password';
import Button from 'components/button';
import { useHistory } from "react-router-dom";

import {
  LinkButton,
} from 'components/common/styles';
import AuthContainer from '../authContainer'

import {
  Footer
} from '../login/styles';

const EnterPassword = () => {
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const history = useHistory();

  const isDisable = password && repeatedPassword && repeatedPassword === password && password.length < 6;


  const changeRoute = (route) => {
    history.push(route);
  }

  return (
    <AuthContainer modalTitle='Please, enter new password' pageTitle='Reset password'>

      <div style={{ marginBottom: 30 }}>
        <div style={{ margin: '10px 0' }}>
          <PasswordInput onChange={setPassword} value={password} placeholder="Password" />
        </div>
        <div style={{ margin: '10px 0' }}>
          <PasswordInput onChange={setRepeatedPassword} value={repeatedPassword} placeholder='Repeat password' />
        </div>
      </div>
      <div>
        <Button style={{ marginTop: 10 }} disabled={!isDisable}>
          Update password
        </Button>

        <Footer>
          <LinkButton onClick={() => changeRoute('/login')}>
            Back to sign in
          </LinkButton>
        </Footer>
      </div>
    </AuthContainer>
  );
};

export default EnterPassword;
