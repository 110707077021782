export const saveCard = (data) => (dispatch) => {
  dispatch({
    type: 'SAVE_CARD',
    data,
  });
};

export const getCards = (data) => (dispatch) => {
  dispatch({
    type: 'GET_CARDS',
    data,
  });
};

export const deleteCard = (cardId) => (dispatch) => {
  dispatch({
    type: 'DELETE_CARD',
    data: {
      cardId
    },
  });
};

export const updateCard = (cardId, data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CARD',
    data: {
      cardId,
      data
    },
  });
};