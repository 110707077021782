import styled from 'styled-components';
import { LinkButton } from 'components/common/styles';

export const ModalContent = styled.div`
    width: 100%;
    max-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Description = styled.span`
    display: flex;
    justify-content: center;
    line-height: 24px;
    font-size: 16px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-stretch: normal;
    font-weight: 400;
    color: rgba(51,51,51,.5);
    text-align: center;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 5px;
`;

export const MailIconContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 5px;
`;

export const LoginLink = styled(LinkButton)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`