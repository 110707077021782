import styled from 'styled-components';
import {
  Settings as SettingsIcon,
} from '@material-ui/icons';
import * as CONSTANTS from 'constants/styles';

export const Header = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 30px;
  width: 100%;
`;

export const SettingsButton = styled(SettingsIcon)`
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
`;


export const SubscriptionOverviewWrapper = styled.div`
`;

export const SubscriptionOverviewItem = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 8%);
  background-color: #fafafa;
  padding: 20px;
  margin: 12px 0;
`;

export const Overview = styled(SubscriptionOverviewItem)`
  flex: 0 0 52%;
`;

export const BillingPeriod = styled(SubscriptionOverviewItem)`
  flex: 0 0 45%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DateCreated = styled.div`
  font-size: 14px;
  color: ${CONSTANTS.COLORS.GRAY_G3};
  text-align: left;
`;

export const CancelSubscriptionButton = styled.div`
  color: #FF5D22;
  display: flex;
  padding: 0;
  text-transform: none;
  padding-top: 10px;
  cursor: pointer;

  &:hover {
    background: none;
    text-decoration: underline;
    outline: none;
  }
`;


export const BillingPeriodContainer = styled.div`
  height: ${CONSTANTS.DESKTOP_USAGE_ITEM_HEIGHT}px;
`;

export const StatisticContainer = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;

export const StatisticItem = styled.div`
  flex: 0 0 45%;
  background-color: ${CONSTANTS.COLORS.GRAY_G4};
  padding: 12px 8px;
  border-radius: 6px;
`;

export const StatisticValue = styled.div`
  font-weight: 500;
  text-align: left;
  font-size: 14px;
`;

export const StatisticTitle = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${CONSTANTS.COLORS.GRAY_G3};
  text-align: left;
`;

export const StatusIndicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #38b42d;
  margin-left: 10px;
  cursor: pointer;
`;
