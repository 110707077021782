import React from 'react';

import { BaseFooterContatiner } from './styles'


const BaseFooter = () => (

    <BaseFooterContatiner>
        {`©${new Date().getFullYear()} RocketScrape.com. All rights reserved`}
    </BaseFooterContatiner>
)

export default BaseFooter;