import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccountSettings from 'components/accountSettings';
import * as CONSTANTS from 'constants/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(CONSTANTS.SPACING_SMALL),
        marginBottom: theme.spacing(CONSTANTS.SPACING_SMALL),
        width: '100%',
      },
    },
    paper: {
      padding: theme.spacing(CONSTANTS.SPACING_LARGE),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: CONSTANTS.SHADOWS.PAPER_SHADOW,
    },
    buttonAdd: {
      margin: '10px 0'
    }
}));

const SettingsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>RocketScrape - Settings</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AccountSettings />
        </Grid>
        {/**
         *  Temporary removed payment methods
         */}
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container>
              <Typography variant="h6">Payment Methods</Typography>
              <Divider />
              <CardsList />
              <Button className={classes.buttonAdd} variant="contained" color="primary" onClick={handleOpen}>
                Add
              </Button>
              <Divider />
            </Container>
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default SettingsPage;

