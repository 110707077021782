import React, { useState, useEffect } from 'react';
import {
  MeterContainer,
  MeterItem,
} from './styles';

const PasswordStrengthMeter = ({ password }) => {
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    if (password) setStrength(password.length);
    else setStrength(0);
  }, [password]);

  const color = () => {
    if (strength > 6) return '#2bd982';
    if (strength > 3) return '#ffd500';
    return '#ff0040';
  };

  const items = () => [1, 2, 3].map((i) => (
    <MeterItem
      key={`meter_item_${i}`}
      style={{
        background: (Math.ceil(strength / 3) >= i) ? color() : '#eee',
      }}
    />
  ));

  return (
    <MeterContainer>
      { items() }
    </MeterContainer>
  );
};

export default PasswordStrengthMeter;
