import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Typography
} from '@material-ui/core';
import {
  CheckCircleOutlineSharp,
  ArrowForwardIosOutlined,
  HighlightOffOutlined,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { getFreshping } from 'data/actions/freshping';
import * as CONSTANTS from 'constants/styles';
import { freshpingLoadingSelector } from 'data/selectors/loading';
import { freshPingStatusSelector } from 'data/selectors/freshping';
import Loader from 'components/loading';

import {
  HealthStatusContainer,
  TitleContainer,
  HealthContainer,
  HealthPageLink,
  HealthForwardContainer,
} from './styles';

const HealthStatus = () => {
  const dispatch = useDispatch();
  const isLoading = false;
  const status = useSelector(freshPingStatusSelector);
  const theme = useTheme();

  // useEffect(() => {
  //   // API freshping id 629195
  //   if (!status) {
  //     dispatch(getFreshping(629195))
  //   }
  // }, [dispatch, status]);

  return (  
    <HealthStatusContainer style={{ justifyContent: isLoading ? 'center' : 'space-between' }}>
      {isLoading ? <Loader /> : 
      <>
        <TitleContainer>
          <Typography variant="h5" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>
            Health status
          </Typography>
        </TitleContainer>
        <HealthContainer>
          {status === 200 ? <CheckCircleOutlineSharp htmlColor={theme.palette.success.light} fontSize="large" /> : <HighlightOffOutlined htmlColor={theme.palette.error.light} fontSize="large" />}
          <Typography variant="h6" style={{ fontWeight: CONSTANTS.DESKTOP_USAGE_ITEM_TITLE_FONT_WEIGHT }}>
            Health: {`${status === 200 ? 'OK' : 'DOWN'}`}
          </Typography>
        </HealthContainer><HealthForwardContainer>
          <HealthPageLink href="https://status.rocketscrape.com/" target="_blank">
            Go to health page
          </HealthPageLink>
          <ArrowForwardIosOutlined />
        </HealthForwardContainer>
      </>}  
    </HealthStatusContainer>
  );
};

export default HealthStatus;
