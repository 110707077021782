import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from '@material-ui/core';
import PaymentForm from 'components/paymentForm'
import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';

import {
  Title
} from './styles'

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: '50%',
    left:  '50%',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));

const AddCardModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { visible } = useSelector(modalsSelector);

  const handleClose = () => {
    dispatch(toggleModal('add_card', false))
  };

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Title>Add Payment Method</Title>
          <PaymentForm />
        </div>
      </Modal>
    </div>
  );
}

export default AddCardModal;

