import React from 'react';
import ReactJson from 'react-json-view'
import rocketPlaceholder from 'assets/icons/rocket_placeholder.svg';

import {
  Container,
  Blur,
  PlaceholderIcon,
  PlaceholderLabel,
  PlaceholderDescription,
  CustomLoader,
  LoadingLabel,
} from './styles';


const ApiRequestResult = ({ result, loading }) =>  {
    return (
        <Container>
            {
                !result || loading ? (
                    <Blur> 
                        {loading ? (
                            <>
                                <LoadingLabel>Scraping your page <span role="img" aria-label="rocket">🚀</span></LoadingLabel>
                                <CustomLoader><div></div><div></div><div></div><div></div></CustomLoader>
                            </>
                        ) : (
                            <>
                                <PlaceholderLabel>Let's start scraping!</PlaceholderLabel>
                                <PlaceholderDescription> Get started by putting url above, and send request! </PlaceholderDescription>
                                <PlaceholderIcon src={rocketPlaceholder} /> 
                            </>
                        )}
                  </Blur>
                ): <></>
            }
            { (result && result.result && typeof result.result === 'string') && <code>{result.result}</code> }
            { (result && result.result && typeof result.result === 'object') &&  <ReactJson src={result} theme="paraiso" /> }
        </Container>
    );
}

export default ApiRequestResult;