import styled from 'styled-components';

export const LoginTitle = styled.div`
  line-height: 30px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
`;

export const SocialButtonContainer = styled.button`
  display: flex;
  align-items: stretch;
  height: 40px;
  box-sizing: content-box;
  cursor: pointer;
  flex: 1 1 auto;
  background: white;
  outline: 0px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  padding: 0px;
  border-radius: 4px;
  width: 100%;
  margin: 30px 0;

  &:hover {
    opacity: 0.9;
  }
`;

export const SocialButtonContent = styled.div`
  line-height: 18px;
  font-size: 15px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-stretch: normal;
  font-weight: 600;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  color: white;
  flex: 1 0 auto;
  padding: 0px 18px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.color};
  border-image: initial;
  border-radius: 0px 4px 4px 0px;
  background:  ${(props) => props.color};
`;

export const SocialButtonIcon = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.color};
  border-image: initial;
  border-radius: 4px 0px 0px 4px;
`;

export const SocialButtonsContainer = styled.div`
  margin-top: 15px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px 40px;
  background: linear-gradient(111.34deg, #2d357a,#0a1142);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @media (max-width: 767px) {
    max-width: 100%;
    justify-content: center;
  }
`;

export const ModalContent = styled.div`
  width: 450px;
  background-color: #fff;
  box-shadow: 0 8px 10px rgb(0 0 0 / 20%), 0 16px 24px rgb(0 0 0 / 14%), 0 6px 30px rgb(0 0 0 / 12%);
  padding: 40px 50px;
  border-radius: 4px;

  @media (max-width: 767px) {
    max-width: 100%;
    justify-content: center;
  }
`;

export const FormDivider = styled.div`
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
  
  :before, :after {
    content: '';
    width: calc(100% - 200px);
    height: 1px;
    background-color: #DFE2E5;
    display: block;
    position: absolute;
    top: 7px;
  }
  
  :after {
    right: 0;
  }
`;

export const Text = styled.div`
  margin-top: 10px;
  line-height: 18px;
  font-size: 14px;
  font-stretch: normal;
  font-weight: 400;
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  span {
    margin-left: 10px;
    margin-top: 11px;
  }
`;