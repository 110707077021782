import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const BarContainer = styled.div`
  margin: 24px 0;
`;

export const BarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const BarTitle = styled.span`
  font-weight: 450;
  font-size: 15px;
  margin: 0;
`;

export const BarLimit = styled.div`
  color: ${CONSTANTS.COLORS.BLUE_B1};
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;