import React, { useState } from 'react';
import {
  LockOutlined, EyeOutlined, EyeInvisibleOutlined,
} from '@ant-design/icons';
import Input from '../input';
import { EmptyButton } from '../common/styles';

const PasswordInput = ({
  onChange, value, placeholder, style,
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <Input
      type={hidePassword ? 'password' : 'text'}
      name="password"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      iconBefore={<LockOutlined />}
      style={style}
      iconAfter={
        hidePassword
          ? (
            <EmptyButton type="button" onClick={() => setHidePassword(false)}>
              <EyeOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </EmptyButton>
          )
          : (
            <EmptyButton type="button" onClick={() => setHidePassword(true)}>
              <EyeInvisibleOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </EmptyButton>
          )
}
    />
  );
};

export default PasswordInput;
