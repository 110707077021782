import React from 'react';
import { Helmet } from 'react-helmet';

import BaseFooter from 'components/baseFooter';

import {
  ModalTitle,
} from 'components/common/styles';

import {
  ModalContent,
  Container,
} from './styles';

import logo from 'assets/icons/logo.svg';

const AuthContainer = ({
    pageTitle = '',
    modalTitle = '',
    children
  }) => {
  return (
    <Container>
      <Helmet>
        <title>RocketScrape - {pageTitle}</title>
      </Helmet>
      <div>
        <a href="https://rocketscrape.com">
          &#x3c; Back to RocketScrape
        </a>
      </div>
      <ModalContent>
        <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} style={{ width: 300 }} alt="RocketScrape" />
        </div>
        <ModalTitle>
          <span role="img" aria-label="hello">{modalTitle}</span>
        </ModalTitle>

        {children}
      </ModalContent>
      <BaseFooter />
    </Container>
  );
};

export default AuthContainer;
