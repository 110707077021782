import React, { useEffect } from 'react';
import capitalize from 'lodash/capitalize'; 
import { useDispatch, useSelector } from 'react-redux';
import {
  Button
} from '@material-ui/core';
import { getPlans } from 'data/actions/plans'
import { plansSelector } from 'data/selectors/plans';
import Loading from 'components/loading';
import * as CONSTANTS from 'constants/styles';

import { Container, PlanItem, PlanSubItem, Title, SubTitle, ButtonsWrapper } from './styles';

const PlansList = () => {
  const dispatch = useDispatch();
  const plans = useSelector(plansSelector);

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const handlePlanSelect = () => {
    if (window?.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };

  const plansView = plans.map((plan) => (
    plan.name !== 'free' && <PlanItem key={plan?._id}>
      <PlanSubItem>
          <Title color={CONSTANTS.COLORS.BLUE_B1}>{capitalize(plan.name)}</Title>
          <SubTitle>{`${plan.credits} requests / month`}</SubTitle>
      </PlanSubItem>
      <PlanSubItem>
          <Title>{`$${plan.price} / mo`}</Title>
          <SubTitle>Billed monthly</SubTitle>
      </PlanSubItem>
      <PlanSubItem>
        <ButtonsWrapper>
          <Button variant="contained" color="primary" onClick={handlePlanSelect}>
            Select
          </Button>
        </ButtonsWrapper>
      </PlanSubItem>
    </PlanItem>
  ));

  return (
    <Container>
      {
        plans.length ? plansView : <Loading height="200px" />  
      }
    </Container>
  );
};

export default PlansList;
