import get from 'lodash/get';
import { createSelector } from 'reselect';

export const baseUsageSelector = (state) => get(state, 'usage', {});

export const usageAnalyticsSelector = createSelector(
  baseUsageSelector,
  (usage) => usage?.usageAnalytics || null,
);

export const usageLogsSelector = createSelector(
  baseUsageSelector,
  (usage) => usage?.usageLogs || null,
);
