import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCF6yZyA1Qy6z0Cy2zpJw8p1uKO01-yTcc",
  authDomain: "scrapify-79876.firebaseapp.com",
  projectId: "scrapify-79876",
  storageBucket: "scrapify-79876.appspot.com",
  messagingSenderId: "377764302774",
  appId: "1:377764302774:web:590f7a195c6e8509bbfb17",
  measurementId: "G-FE1WC8DEDZ"
};

firebase.initializeApp(firebaseConfig);

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const FacebookProvider = new firebase.auth.FacebookAuthProvider();
export const firebaseApp = firebase.auth();

export const resendEmailVerification = () => {
  const currentUser = firebaseApp?.currentUser
  if (currentUser) currentUser.sendEmailVerification()
}

export const useFirebaseAuthentication = () => {
  const [authUser, setAuthUser] = useState(localStorage.getItem('authUser'));

  useEffect(() => {
    const unlisten = firebaseApp.onAuthStateChanged(
      (user) => {
        if (user) {
          setAuthUser(user);
          localStorage.setItem('authUser', JSON.stringify(authUser));
        } else {
          setAuthUser(null);
          localStorage.removeItem('authUser');
        }
        return true;
      },
    );
    return () => {
      unlisten();
    };
  });

  return authUser;
};
