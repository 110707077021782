import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button
} from '@material-ui/core';
import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { deleteCard } from 'data/actions/cards'


import {
  Title
} from './styles'

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: '50%',
    left:  '50%',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  body: {
    padding: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const DeleteCardModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { visible, options } = useSelector(modalsSelector);

  const handleClose = () => {
    dispatch(toggleModal('delete_card', false))
  };

  const handleDeleteCard = () => {
    dispatch(deleteCard(options?.cardId))
    dispatch(toggleModal('delete_card', false))
  }

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Title>Delete Payment Method</Title>
          <div className={classes.body}>
            <div className={classes.buttonsContainer}>
              <Button
                onClick={() => dispatch(toggleModal('card_modal', false))}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={handleDeleteCard}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteCardModal;

