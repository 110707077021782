const initialState = {
  playgroundResult: null
};
  
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_API_REQUEST': {
      if (action?.payload?.err) {
        return {
          ...state,
          playgroundResult: {
            result: action?.payload?.err,
          },
        };
      }
      return {
        ...state,
        playgroundResult: {
            result: action.payload
        }
      }
    }
    case 'CLEAR_RESULT': {
      return {
        ...state,
        playgroundResult: null
      }
    }
    default:
      return state;
  }
};
  