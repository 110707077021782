import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseModalsSelector = (state) => get(state, 'modals', {});
export const modalsSelector = createSelector(
  baseModalsSelector,
  (modals) => modals,
);

export const modalOptionsSelector = createSelector(
  baseModalsSelector,
  (data) => data.options,
);

