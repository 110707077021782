import styled from 'styled-components';
import * as CONSTANTS from 'constants/styles';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const InfoContainer = styled.div`
  height: ${CONSTANTS.DESKTOP_USAGE_ITEM_HEIGHT}px;
`;

export const PlaceholderLabel = styled.span`
    display: flex;
    color: black;
    font-size: 26px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 20px;
`;

export const PlaceholderDescription = styled.span`
    display: flex;
    color: black;
    opacity: 0.8;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
`
