import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    color: rgb(33, 33, 33);
    padding: 0px 6px;
    border-radius: 2px;
    background: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-image: initial;
    transition: border 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    height: 40px;
    position: relative;
    flex: 1 1 0%;
    margin: 5px 0;

    input {
        line-height: 18px;
        font-size: 14px;
        font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-stretch: normal;
        font-weight: 400;
        color: rgb(33, 33, 33);
        display: block;
        width: 102%;
        height: 38px;
        box-sizing: border-box;
        box-shadow: none;
        padding: 18px 6px 6px;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        border-radius: 0px;
        outline: none;
        background: transparent;
    }
`;

export const Placeholder = styled.div`
    line-height: 18px;
    font-size: 14px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-stretch: normal;
    font-weight: 400;
    color: rgb(117, 117, 117);
    display: flex;
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: left top;
    pointer-events: none;
    padding: 0px 6px;
    overflow: hidden;
    transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
`;
