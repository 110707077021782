import * as moment from 'moment';

export const generateData = (dataset, period, timezone = 'UTC') => {
  const labels = [];
  const usageSuccessData = [];
  const usageErrorData = [];
  const usageError = dataset?.usageError || []
  const usageSuccess = dataset?.usageSuccess || []

  switch (period) {
    case 'month': {
      for(let i = 1; i<= 30; i++) {
        const date = moment().tz(timezone).subtract(30, 'days').add(i, 'days');
        const month = +date.format('M')
        const day = +date.format('D')
        labels.push(date.format('MMMM, D'))
        usageSuccessData.push(usageSuccess.find(({ _id }) => _id.month === month && _id.day === day)?.count || 0)
        usageErrorData.push(usageError.find(({ _id }) => _id.month === month && _id.day === day)?.count || 0)
      }
      break;
    }
    case 'day': {
      for(let i = 1; i<= 24; i++) {
        const date = moment().tz(timezone).subtract(24, 'hour').add(i, 'hour');
        const month = +date.format('M')
        const day = +date.format('D')
        const hour = +date.format('H')
        labels.push(date.format('HH:00'))
        usageSuccessData.push(usageSuccess.find(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour)?.count || 0)
        usageErrorData.push(usageError.find(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour)?.count || 0)
      }
      break;
    }
    case 'hour': {
      for(let i = 1; i<= 60; i++) {
        const date = moment().tz(timezone).subtract(60, 'minute').add(i, 'minute');
        const month = +date.format('M')
        const day = +date.format('D')
        const hour = +date.format('H')
        const minute = +date.format('mm')
        labels.push(date.format('HH:mm'))
        usageSuccessData.push(usageSuccess.find(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour && _id.minute === minute)?.count || 0)
        usageErrorData.push(usageError.find(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour && _id.minute === minute)?.count || 0)
      }
      break;
    }
    default:
      break;
  }

  return {
    labels,
    datasets: [
      {
        label: 'failed',
        data: usageErrorData,
        backgroundColor: "rgb(235, 64, 52)",
        borderWidth: 1
      },
      {
        label: 'success',
        data: usageSuccessData,
        backgroundColor: "rgb(52, 235, 104)",
        borderWidth: 1
      },
    ]
  }
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        minRotation: 45
      }
    },
    y: {
      stacked: true
    }
  }
};