import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { accountEventsSubscription } from 'data/actions/user'
import Sidebar from '../sidebar';
import { Container, Main } from './styles'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    maxWidth: '100%',
  },
}));

const  CommonContainer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(accountEventsSubscription());
    }, 5000)
  }, [dispatch])

  const classes = useStyles();
  return (
    <Container>
      <CssBaseline />
      <Sidebar />
      <Main className={classes.content}>
        {children}
      </Main>
    </Container>
  );
}

export default CommonContainer;
