import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
}from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardInput from 'react-credit-card-input';
import { toggleModal } from 'data/actions/modals'
import { saveCard } from 'data/actions/cards'

import {
  Title,
  ButtonsContainer,
  Container,
  ButtonComponent
} from './styles'

const useStyles = makeStyles((theme) => ({
  root: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
      },
  },
  input: {

  }
}));

 
const PaymentForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const isDisabledSave = !cvc || !expiry || !name || !number

  const handleSave = () => {
    dispatch(saveCard({ cvc, expiry, name, number }))
    dispatch(toggleModal('card_modal', false))
  }

  return (
      <Container className={classes.root}>
        <Title>Card details</Title>
        <TextField
          placeholder="Card holder"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={classes.input}
        />

        <CreditCardInput
          cardNumberInputProps={{ value: number, onChange: (e) => setNumber(e.target.value) }}
          cardExpiryInputProps={{ value: expiry, onChange: (e) => setExpiry(e.target.value) }}
          cardCVCInputProps={{ value: cvc, onChange: (e) => setCvc(e.target.value) }}
          fieldClassName="input"
          containerStyle={{
            width: '100%'
          }}
        />
        <ButtonsContainer>
          <ButtonComponent
            onClick={() => dispatch(toggleModal('card_modal', false))}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            disabled={isDisabledSave}
            onClick={handleSave}
          >
            Save
          </ButtonComponent>
        </ButtonsContainer>
      </Container>
    );
}

export default PaymentForm;
