import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom,#f9f9f9 10%,#eeeff3 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #39393B;
`;

export const Label = styled.span`
    margin-top: 30px;
    animation: 2s ${fadeInAnimation};
    cursor: pointer;
    font-weight: 600;
`;

export const LogoContainer = styled.div`
    position: absolute;
    bottom: 5%;
    cursor: pointer;
`;
