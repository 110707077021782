import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Main = styled.main`
 @media (max-width: 600px) {
    margin-top: 56px;
  }
`;

export const Icon = styled.img`
  width: 36px;
`;

export const ModalTitle = styled.div`
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Source Sans Pro",Helvetica,Arial,sans-serif;
`;

export const LinkButton = styled.span`
  cursor: pointer;
  color: rgb(0, 85, 255);
  line-height: 18px;
  font-size: 14px;
  font-stretch: normal;
  font-weight: 400;
  text-align: center;
  margin: 12px 0px 0px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const EmptyButton = styled.button`
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
`;


export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  line-height: 12px;
  font-size: 12px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-stretch: normal;
  font-weight: 400;
  color: rgb(255, 0, 64);
`;
