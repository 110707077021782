export const getUsageAnalytics = (period = 'month', onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'GET_USAGE_ANALYTICS',
    payload: {
      endpoint: `/api/user/usage/analytics?period=${period}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['usageAnalytics'],
    onSuccess,
  });
};


export const getUsageLogs = (page = 1, size = 10) => (dispatch) => {
  dispatch({
    type: 'GET_USAGE_LOGS',
    payload: {
      endpoint: `/api/user/usage/logs?page=${page}&size=${size}`,
      method: 'GET',
      delayLoad: 0,
    },
    components: ['usageLogs']
  });
};