import axios from 'axios';
import get from 'lodash/get';
import { firebaseApp } from '../../auth';

const API_HOST = process.env.REACT_APP_API_URL;

export default () => (next) => async (action) => {
  const endpoint = get(action, 'payload.endpoint');
  const url = get(action, 'payload.url');
  const method = get(action, 'payload.method');
  const body = get(action, 'payload.body');
  const params = get(action, 'payload.params', {});
  const components = get(action, 'components', null);
  const onSuccess = get(action, 'onSuccess', null);
  const onFailed = get(action, 'onFailed', null);
  const delayLoad = get(action, 'payload.delayLoad', 1000);
  const type = get(action, 'type');

  if (get(action, 'payload.event')) return next(action);

  const triggerLoadingAction = (loading) => {
    if (components) {
      next(
        {
          type: 'UPDATE_LOADING_COMPONENT',
          data: {
            components,
            loading,
          },
        },
      );
    }
  };

  if (get(action, 'data')) return next(action);
  const headers = {};

  let loading = true;

  setTimeout(() => {
    if (loading) {
      triggerLoadingAction(true);
    }
  }, delayLoad);

  try {
    const storage = localStorage.getItem('auth');
    const auth = JSON.parse(storage);
    const expirationTime = get(auth, 'stsTokenManager.expirationTime', false);
    let accessToken = get(auth, 'stsTokenManager.accessToken', false);

    if (Date.now() >= expirationTime && firebaseApp.currentUser) {
      accessToken = await firebaseApp.currentUser.getIdToken(true);
    }

    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`;
    }
  } catch (err) {
    headers.authorization = false;
  }

  axios({
    url: (url) ? url : `${API_HOST}${endpoint}` ,
    method,
    data: body,
    headers,
    params,
  }).then((res) => {
    const data = get(res, 'data');
    if (onSuccess) onSuccess(data);

    loading = false;
    setTimeout(() => triggerLoadingAction(false, 0));

    return next({
      ...action,
      payload: data,
    });
  }).catch((err) => {
    setTimeout(() => triggerLoadingAction(false, 0));
    if (type === 'SEND_API_REQUEST') {
      const data = get(err, 'response.data');
      return next({
        ...action,
        payload: {
          err: data,
        },
      })
    }
    if (onFailed) onFailed(err);
    return false;
  });

  return false;
};
