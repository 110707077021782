import React, { useState } from 'react';

import EmailInput from 'components/customInputs/email';
import Button from 'components/button';
import { firebaseApp } from 'auth';
import { LinkButton } from 'components/common/styles';

import AuthContainer from '../authContainer'
import { useHistory } from "react-router-dom";

import {
  Description
} from './styles';

import {
  TextContainer
} from '../signUp/styles'

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();

  const disabled = !email;

  const changeRoute = (route) => {
    history.push(route);
  }

  const resetPassword = async () => {
    try {
      await firebaseApp.sendPasswordResetEmail(email);
    } catch {
    } finally {
      changeRoute('/reset-letter');
    }
  };

  return (
    <AuthContainer modalTitle='Forgot your password? 😳' pageTitle='Reset Password'>
      <Description>
        We will send you a link to reset your password.
      </Description>

      <div style={{ margin: '15px 0' }}>
        <EmailInput onChange={setEmail} value={email} placeholder="E-mail address" />
      </div>

      <div>
        <Button onClick={resetPassword} disabled={disabled}>Reset Password</Button>
        <TextContainer>
          <span>
            Don't need to reset?
            {' '}
            <LinkButton onClick={() => changeRoute('/login')}>
              Login
            </LinkButton>
          </span>
        </TextContainer>
      </div>
    </AuthContainer>
  );
};

export default ResetPassword;
